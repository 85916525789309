import React from 'react'
import { Link } from 'react-router-dom'
import Translate from '../containers/Translate'

const FullWidthVideo = props => {
    if (!props.data) {
        return null
    }

    const { videoId } = props.data
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&modestbranding=1&controls=0&fs=0&hl=0&loop=1&rel=0`

    return (
        <>
            <div className="full-width-video">
                <iframe title="youtube-video" src={videoUrl} allow="autoplay" allowFullScreen></iframe>
            </div>
            <div className="full-width-link">
                <Link to="/video">
                    <Translate value="full_width_link_label" />
                </Link>
            </div>
        </>
    )
}

export default FullWidthVideo