import React from 'react'
import Menu from './Menu'

class Footer extends React.Component {
    changeLanguage(language) {
        this.props.changeLanguage(language)
    }

    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="social">
                        <div className="contact">
                            <h3>Contact</h3>
                            <p>emiandtabita@gmail.com</p>
                            <p>+4 0754 671 272</p>
                            <div className="icons">
                                <a href="https://www.instagram.com/emi.and.tabita/" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.facebook.com/emiandtabita/" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-facebook-official" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCZmD276oV11_t7jKAc3D2CA" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-youtube" aria-hidden="true"></i>
                                </a>
                                <a href="mailto:emiandtabita@gmail.com">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div className="brand">
                            <h1>Emi And Tabita</h1>
                            <h2>FINE ART PHOTOGRAPHER AND VIDEOGRAPHER</h2>
                        </div>
                        <div className="links">
                            <div className="language-links">
                                <span onClick={this.changeLanguage.bind(this, 'ro')}>RO</span> / <span onClick={this.changeLanguage.bind(this, 'en')}>EN</span>
                            </div>
                            <Menu vertical />
                        </div>
                    </div>
                    <div className="small-footer">
                        <div className="made-by">Website made by <a href="https://fiveem.com">Fiveem</a></div>
                        <div className="copyright">© Copyright 2019 EmiAndTabita.com</div>
                        <div className="filler" />
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer