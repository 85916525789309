import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import initializeReactGA from './utils/analytics'

import Home from './screens/Home'
import About from './screens/About'
import Contact from './screens/Contact'

import PhotoListScreen from './screens/PhotoListScreen'
import PhotoScreen from './screens/PhotoScreen'
import VideoListScreen from './screens/VideoListScreen'

import ScrollToTop from './components/ScrollToTop'

import { fetchPhotoData } from './actions/photo'
import { fetchTestimonialsData } from './actions/testimonials'
import { fetchVideoData } from './actions/video'

import 'font-awesome/scss/font-awesome.scss'

initializeReactGA()

class App extends React.Component {
    componentDidMount() {
        this.props.fetchPhotoData()
        this.props.fetchVideoData()
        this.props.fetchTestimonialsData()
    }

    render() {
        return (
            <Router>
                <div className="page">
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/photo" component={PhotoListScreen} />
                            <Route exact path="/photo/:id" component={PhotoScreen} />
                            <Route exact path="/video" component={VideoListScreen} />
                            <Redirect to="/" />
                        </Switch>
                    </ScrollToTop>
                </div>
            </Router>
        )
    }
}

const mapDispatchToProps = {
    fetchPhotoData,
    fetchTestimonialsData,
    fetchVideoData,
}

export default connect(null, mapDispatchToProps)(App);
