import React from 'react'
import { Link } from 'react-router-dom'

class ResourceList extends React.Component {
    render() {
        const { data, resourceUrlPrefix } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <div className="slides">
                {data && data.length && data.map(resource => {
                    const style = {
                        backgroundImage: `url('${resource.thumbnail}')`
                    }

                    return (
                        <Link to={`${resourceUrlPrefix}/${resource.id}`} key={resource.id} className="slide">
                            <div className="background" style={style} />
                            <div className="foreground">
                                <h4>{resource.title}</h4>
                                <h5>{resource.subtitle}</h5>
                            </div>
                        </Link>
                    )
                })}
            </div>
        )
    }
}

export default ResourceList