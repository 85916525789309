const translation = {
    en: {
        menu_home: "Home",
        menu_about: "About us",
        menu_photo: "Photo Portfolio",
        menu_video: "Video Portfolio",
        menu_blog: "Blog",
        menu_contact: "Contact",
        
        btl_title: "behind the lens",

        btl_videographer: "Emi (Videographer)",
        btl_videographer_description_1: "I’m happy to share with you what I see and what I feel behind the camera while I’m working.",
        btl_videographer_description_2: "Every person’s life is unique and special and I want to capture the best of people’s lives, letting the images I capture to speak about life’s beauty and charm.",
        btl_videographer_description_3: "I focus on capturing the most special moments in each person’s life and I’m trying to get them together to form the story of every person as good as possible.",
        btl_videographer_description_4: "In my work, creativity, passion and perseverance are extremely important helping me to do the best work ever.",

        btl_photographer: "Tabita (Photographer)",
        btl_photographer_description_1: "I always thought that photographic art is something very special by its way to express feelings and emotions through a simple image.",
        btl_photographer_description_2: "I really want my photos communicate all the light, love and joy that is in people’s lives.",
        btl_photographer_description_3: "I want to capture every single special moment which will be a treasure in years to come and these pictures will definetely renew in people’s mind and heart all the beautiful moments they lived in the past.",
        btl_photographer_description_4: "Behind the camera is not very easy, but when work is passionately woven it becomes a pleasure, not a duty.",

        instagram_title: "follow on instagram",
        full_width_link_label: "View More Videos",

        contact_groom_and_bride: 'Groom and Bride',
        contact_wedding_date: 'Wedding Date',
        contact_wedding_venue: 'Wedding Venue',
        contact_email: 'Email',
        contact_phone: 'Phone',
        contact_about_us: 'How did you hear about us?',
        contact_details: 'Details',
        contact_submit: 'Submit',
        contact_mandatory: 'All the fields are mandatory!',
        contact_success: 'Message sent successfully!',
        contact_error: 'All the fields are mandatory!',
        contact_submit_error: 'Message cannot be sent! If error persists please contact us at emiandtabita@gmail.com'
    },
    ro: {
        menu_home: "Acasa",
        menu_about: "Despre noi",
        menu_photo: "Portofoliu Foto",
        menu_video: "Portofoliu Video",
        menu_blog: "Blog",
        menu_contact: "Contact",

        btl_title: "in spatele camerei",

        btl_videographer: "Emi (Cameraman)",
        btl_videographer_description_1: "Ma bucur sa pot sa va impartasesc si voua ceea ce vad si simt in spatele camerei de filmat. Uneori e greu sa descri sentimentele captate prin obiectivul camerei deoarece acestea sunt conectate cu inima fiecarui om.",
        btl_videographer_description_2: "De fiecare data cand apas butonul Rec, doresc ca prin lumina obiectivului  sa surprind cele mai frumoase momente din viata fiecarui om si sa le imbin creind povestea vietii lor.",
        btl_videographer_description_3: "Creativitatea, pasiunea si perseverenta sunt lucurile de baza care ma ajuta sa lupt, sa cresc si sa evoluez pentru a deveni tot mai bun.",
        btl_videographer_description_4: "",

        btl_photographer: "Tabita (Fotograf)",
        btl_photographer_description_1: "Dintotdeauna am vazut ceva deosebit in arta fotografica si modul in care poti exprima sentimente, emotii doar printr-o fotografie.",
        btl_photographer_description_2: "Ceea ce imi doresc este ca prin fotografiile mele sa transmit lumina, dragoste, bucurie si sa captez fiecare traire emotionanta care sa ramana vii peste mult timp in mintea si inima fiecaruia.",
        btl_photographer_description_3: "In spatele camerei nu este deloc usor,dar cand munca este impletita cu pasiunea, devine o placere, nu o obligatie.",
        btl_photographer_description_4: "",

        instagram_title: "instagram",
        full_width_link_label: "Vezi mai multe videoclipuri",

        contact_groom_and_bride: 'Mire si mireasa',
        contact_wedding_date: 'Data nuntii',
        contact_wedding_venue: 'Locul nuntii',
        contact_email: 'Adresa email',
        contact_phone: 'Telefon',
        contact_about_us: 'Cum ai auzit de noi?',
        contact_details: 'Detalii',
        contact_submit: 'Trimite',
        contact_mandatory: 'Toate câmpurile sunt obligatorii!',
        contact_success: 'Mesajul a fost trimis cu success!',
        contact_error: 'Toate câmpurile sunt obligatorii!',
        contact_submit_error: 'Mesajul nu a putut fi trimis! Dacă eroarea persistă, contactează-ne la emiandtabita@gmail.com'
    }
}

export default translation