import React from 'react'
import { Link } from 'react-router-dom'
import Translate from '../containers/Translate'

const Menu = props => {
    const className = props.isSection ? 'menu menu-section' : 'menu'
    const showBrand = props.showBrand || false
    const vertical = props.vertical || false

    return (
        <nav className={className}>
            <Link className={`border ${!vertical ? "firstLink" : ""}`} to="/"><Translate value="menu_home" /></Link>
            <Link className="border" to="/about"><Translate value="menu_about" /></Link>
            <Link className={!showBrand ? "border" : ""} to="/photo"><Translate value="menu_photo" /></Link>

            {showBrand &&
                <Link className="brand" to="/">
                    <h1>Emi And Tabita</h1>
                    <h2>FINE ART PHOTOGRAPHER AND VIDEOGRAPHER</h2>
                </Link>
            }

            <Link className="border" to="/video"><Translate value="menu_video" /></Link>
            <Link className="border" to=""><Translate value="menu_blog" /></Link>
            <Link className={!vertical ? "lastLink" : ""} to="/contact"><Translate value="menu_contact" /></Link>
        </nav>
    )
}

export default Menu