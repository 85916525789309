import React from 'react'
import Header from '../components/Header'
import Footer from '../containers/Footer'
import PhotoContainer from '../containers/PhotoContainer'

const PhotoScreen = props => {
    const id = props.match.params.id
    return (
        <React.Fragment>
            <Header />
            <PhotoContainer photo={id} />
            <Footer />
        </React.Fragment>
    )
}

export default PhotoScreen