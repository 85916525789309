import { connect } from 'react-redux'
import Gallery from '../components/Gallery'

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.photo.data.map(item => {
            const { id, title, subtitle, thumbnail } = item
            return { id, title, subtitle, thumbnail }
        })
    }
}

export default connect(mapStateToProps)(Gallery)