import React from 'react'

const YoutubePlayer = props => {
    const { id } = props
    if (!id) {
        return null
    }

    const autoplay = 1
    const videoUrl = `https://www.youtube.com/embed/${id}?autoplay=${autoplay}&modestbranding=0`
    return (
        <div className="youtube-player-container">
            <iframe title="youtube-video" src={videoUrl} allow="autoplay" allowFullScreen></iframe>
        </div>
    )
}

export default YoutubePlayer