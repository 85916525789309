import React from 'react'

const Photo = props => {
    if (!props.data) {
        return null
    }

    const { title, gallery } = props.data

    return (
        <div className="photo">
            <div className="top">
                <h3 className="title">{title}</h3>
            </div>
            <div className="images-list">
                {gallery.map((image, index) => <img key={index} src={image} alt="" />)}
            </div>
        </div>
    )
}

export default Photo